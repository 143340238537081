import React from "react";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";
import styled, { createGlobalStyle } from "styled-components";

// const summary = `Freelance Web Developer with 15+ years experience in the web industry,
// having worked on a wide range of projects from small bespoke sites to
// building sites for large businesses from start to finish.`;

// const summary = `
// 15+ years experience in the web industry crafting beautiful and functional websites in React, Javascript and CSS.
// Expert in transforming designs into fully functional layouts that works across a range of devices.
// `;

const summary = `
Front-end developer specialising in React, CSS and Javascript.
15+ years experience crafting beautiful and functional websites.
Expert in transforming designs into fully responsive layouts.
`;

// const techSkills = [
//   "React, Next.js, Gatsby, Redux, React Native, Vue.js",
//   "CSS, SASS, Styled Components, Bootstrap, Material UI",
//   "Javascript, Typescript, ES6, Webpack",
//   "React Spring, Greensock, Three.js",
//   "Apollo, GraphQL, consuming REST APIs",
//   "Jest, Enzyme",
//   "Git, Storybook, Agile",
//   "Drupal, Contentful",
// ];

const techSkills = [
  "React, React hooks, Next.js, Gatsby, Redux, React Native, Vue.js",
  "CSS, SASS, Styled Components, Bootstrap, Material UI",
  "Javascript, ES6, Typescript, Webpack",
  "HTML, HTML5, semantic markup",
  "React Spring, Greensock, Three.js",
  "Apollo, GraphQL, consuming REST APIs",
  "Jest, Enzyme",
  "Git, Storybook, Agile",
  "Contentful, Drupal, PHP, SQL",
];

const PersonalSkills = [
  "Strong attention to detail",
  "Highly organised in fast paced environments",
  "Pragmatic problem solving skills",
  "Conscientious and enthusiastic team player with a positive approach",
  "Self motivated, quick to learn new skills",
];

const education = [
  {
    school: "Victoria University of Wellington",
    // date: "1999 – 2002",
    items: [
      {
        title: "Bachelor of Science (BSc)",
        major: "Major in Computer Science",
      },
      {
        title: "Bachelor of Commerce and Administration",
        major: "Major in E-commerce",
      },
    ],
  },
  {
    school: "Yoobee School of Design",
    // date: "2003 – 2004",
    items: [{ title: "Diploma of Graphic Design" }],
  },
];

const experience = [
  {
    name: "Concept & Code",
    title: "Freelance Front-end Developer",
    date: "Jul 2016 – present (self-employed)",
    items: [
      {
        job: "Covid19map.nz",
        tech: "React, Next.js, Styled components, Typescript",
        desc:
          "Created New Zealand’s first live map and infographics of Covid-19 cases",
      },
      {
        job: "Realestate.co.nz",
        tech: "Ember, Tailwind CSS",
        desc:
          "Rebuilt the entire front-end of New Zealand’s largest real estate website. Ember.js component development",
      },
      {
        job: "Mazda New Zealand",
        tech: "Vue.js, SASS, Drupal",
        desc:
          "Complete retheme of the Mazda NZ website. Added interactions with Vue.js, and built Drupal backend modules for various site enhancements",
      },
      // {
      //   job: "Expenses app",
      //   tech: "React Native, Redux, Typescript, Contentful",
      //   desc:
      //     "iOS and Android app for recording expenses, uploads data to Contentful",
      // },
      {
        job: "TVNZ Antarctica / undertheice.tvnz.co.nz",
        tech: "React, SASS",
        desc:
          "Interface development for Answers Under the Ice, an immersive exploration of Antarctica",
      },
    ],
  },
  {
    name: "Nintex",
    title: "Senior Front-end Developer",
    date: "Sep 2020 – present (contract)",
    items: [
      {
        job: "Promapp",
        tech: "React, SASS, Typescript",
        desc:
          "Retheme of the existing cloud-based business process web app, unifying styles to bring inline with the global product style guide",
      },
    ],
  },
  {
    name: "Osynlig",
    title: "Senior Front-end Developer",
    date: "Apr – May 2020 (contract)",
    items: [
      {
        job: "Wallabies Travel",
        tech: "React, Next.js, Styled Components, Apollo, GraphQL",
        desc: "Front-end build of website and booking form",
      },
    ],
  },
  {
    name: "Isobar",
    title: "Senior Front-end Developer",
    date: "Jan – Apr 2020 (contract)",
    items: [
      {
        job: "AJ Hackett",
        tech: "React, Next.js, Styled Components, Apollo, GraphQL",
        desc:
          "Front-end rebuild of AJ Hackett’s network of international sites. Implementation of a multi-lingual booking system for activities, interfacing with a Silverstripe backend via GraphQL",
      },
    ],
  },
  // {
  //   name: "Accenture Interactive",
  //   title: "Senior Front-end Developer",
  //   date: "Mar 2015 – Jul 2016",
  //   items: [
  //     {
  //       job: "Warehouse Money",
  //       tech: "Angular, Bootstrap, SASS",
  //       desc:
  //         "Front-end build of the Warehouse Money website. Built the credit card application form in Angular",
  //     },
  //     // {
  //     //   job: "Australian Brandenburg Orchestra",
  //     //   tech: "Bootstrap, SASS, Jquery",
  //     //   desc:
  //     //     "Front-end build, integration with an Umbraco backend. Awarded Webby Official Honoree for Best User Interface",
  //     // },
  //     // {
  //     //   job: "Other projects",
  //     //   desc:
  //     //     "Warehouse Money, Meadow Fresh, Vogel’s, Depression.org.nz – The Journal",
  //     // },
  //   ],
  // },
];

export const CVStyle = createGlobalStyle`
  @page {
    background: #e3e2dd;
    size: A4;
    margin: 7mm;
    /* @bottom-right {
       content: counter(page) " / " counter(pages);
    } */
  }
  body {
    background: #e3e2dd;
  }
`;

const StyledCV = styled.div`
  color: #496447;
  padding: 0.8cm 1cm 0;
  font-family: "Montserrat", sans-serif;
  font-size: 8pt;
  line-height: 13pt;
  h1 {
    margin: 0 0 0.3cm;
    font-size: 10pt;
  }
  h2 {
    margin: 0 0 0.3cm;
    font-size: 13pt;
  }
  h3 {
    font-size: 12pt;
    margin: 0 0 0.5cm;
    :not(:first-child) {
      margin-top: 1cm;
    }
  }
  h4 {
    margin: 0 0 0.5mm;
    font-size: 9pt;
    font-weight: 600;
  }
  h5 {
    margin: 0 0 1mm;
    font-size: 9pt;
    font-weight: 600;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    margin: 0 0 1cm;
    font-size: 11pt;
    line-height: 16pt;
    font-weight: 600;
  }
  ul {
    margin-top: 1mm;
    padding-left: 4mm;
  }
  li {
    margin-bottom: 1mm;
  }
  strong {
    font-weight: 600;
  }
  header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: 0.7cm;
    margin-bottom: 0.7cm;
    border-bottom: solid 0.1mm currentColor;
    font-size: 9pt;
    line-height: 15pt;
  }
  .right {
    text-align: right;
  }
  main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1cm;
  }
`;

const CV = () => (
  <StyledCV>
    <CVStyle />
    <SEO
      title="CV"
      meta={[
        {
          name: "robots",
          content: "noindex, nofollow",
        },
      ]}
    />

    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>

    <header>
      <div className="right">
        <h1>Front-end Developer</h1>
        Auckland, New Zealand
        <br />
        <a href="mailto:mail@dixoncheng.com">mail@dixoncheng.com</a>
        <br />
        <a href="tel:0211103335">021 110 3335</a>
        <br />
      </div>
      <div>
        <h2>Dixon Cheng</h2>
        <a
          href="https://dixoncheng.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          dixoncheng.com
        </a>
        <br />
        <a
          href="https://github.com/dixoncheng"
          target="_blank"
          rel="noopener noreferrer"
        >
          github.com/dixoncheng
        </a>
        <br />
        <a
          href="https://www.linkedin.com/in/dixon-cheng/"
          target="_blank"
          rel="noopener noreferrer"
        >
          linkedin.com/in/dixon-cheng
        </a>
      </div>
    </header>
    <main>
      <div>
        <p>{summary}</p>
        <h3>KEY SKILLS</h3>
        <h4>Technical Skills</h4>
        <ul>
          {techSkills.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        <h4>Personal Attributes</h4>
        <ul>
          {PersonalSkills.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        <h3>EDUCATION</h3>
        {education.map(({ school, date, items }) => (
          <div key={school}>
            <h4>{school}</h4>
            {date}
            <ul>
              {items.map(({ title, major }) => (
                <li key={title}>
                  {title}
                  {major && (
                    <>
                      <br />
                      {major}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div>
        <h3>EXPERIENCE</h3>
        {experience.map(({ name, title, date, items }) => (
          <div key={name}>
            <h4>{name}</h4>
            <h5>{title}</h5>
            {date}
            <ul>
              {items.map(({ job, tech, desc }) => (
                <li key={job}>
                  <strong>{job}</strong>

                  {tech && (
                    <>
                      <br />
                      <em>{tech}</em>
                    </>
                  )}
                  {desc && (
                    <>
                      <br />
                      {desc}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </main>
  </StyledCV>
);

export default CV;
